export const SAVE_MAX = 'SAVE_MAX';
export const SAVE_MAX_SUCCESS = 'SAVE_MAX_SUCCESS';
export const SAVE_MAX_ERROR = 'SAVE_MAX_ERROR';

export const GET_MAX = 'GET_MAX';
export const GET_MAX_SUCCESS = 'GET_MAX_SUCCESS';
export const GET_MAX_ERROR = 'GET_MAX_ERROR';

export const UPDATE_MAX = 'UPDATE_MAX';
export const RESET_MAX = 'RESET_MAX';


export default {
    SAVE_MAX,
    SAVE_MAX_SUCCESS,
    SAVE_MAX_ERROR,
    GET_MAX,
    GET_MAX_SUCCESS,
    GET_MAX_ERROR,
    UPDATE_MAX,
    RESET_MAX
};
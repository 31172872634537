import React from 'react';
import PropTypes from "prop-types";
import { useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { logoutUserAction } from '../../../state/ducks/auth/actions';
import { addAlertAction } from '../../../state/ducks/alerts/actions';

import Grid from '@material-ui/core/Grid';
import { PrimaryButton, SecondaryButton } from '../../common/buttons/buttons';
import PersonIcon from '@material-ui/icons/Person';
import InfoPanel from '../infoPanel/infoPanel';
import SettingsMenu from './menu';

function Toolbar({ conferences, accessPoints, isAdmin, isReserving, actions: { logoutUserAction, addAlertAction } }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logoutUserAction()
    }

    const handleClickReserve = () => {
        if(!conferences.some(c => c.status === 'active') || !conferences.length) {
            addAlertAction({
                text: 'No conferences available for reservation',
                severity: 'error'
            })
        } else if (!accessPoints.length) {
            addAlertAction({
                text: 'No access points available for reservation',
                severity: 'error'
            })
        } else {
            history.push("/reserve");
        }
    }

    return (
        <Grid container direction="column" spacing={1}>
            <header>
                <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <Grid xs item>
                        <PrimaryButton 
                            disabled={!conferences.length && !accessPoints.length || isReserving}
                            variant="contained"
                            size="large"
                            onClick={handleClickReserve}>
                                Reserve Conference
                        </PrimaryButton>
                    </Grid>
                    <Grid xs item container direction="row" justify="flex-end" spacing={1}>
                        <Grid xs item style={{flexGrow: 0}}>
                            <SettingsMenu />
                        </Grid>
                        <Grid xs item style={{flexGrow: 0}}>
                            <SecondaryButton 
                                onClick={handleLogout} 
                                size="small" 
                                variant="contained"
                                startIcon={<PersonIcon />}>
                                Logout
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </header>
            <InfoPanel />
        </Grid>
    )
}

const mapStateToProps = state => ({
    conferences: state.reservationState.availableConferences,
    accessPoints: state.reservationState.availableAccessPoints,
    isAdmin: state.authState.isAdmin,
    isReserving: state.reservationState.isLoadingSave
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ 
        logoutUserAction,
        addAlertAction }, 
    dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
import * as types from "./types";

/* State shape
state: {
    color: string,
    message: string,
    colorEdit: string,
    messageEdit: string,
    isLoading: boolean
}
*/

let initialState = {
    color: '',
    message: '',
    colorEdit: '',
    messageEdit: '',
    isLoading: false
}

export default function bannerReducer(state = initialState, action) {
    const response = action.response;
    switch(action.type) {
        case types.RESET_BANNER:
            return {
                ...state,
                colorEdit: state.color,
                messageEdit: state.message
            }

        case types.UPDATE_BANNER:
            return {
                ...state,
                colorEdit: action.color,
                messageEdit: action.message
            }
        
        case types.GET_BANNER_SUCCESS:
            let messageResponse = response.results.message.length ?
                window.atob(response.results.message) : '';
                
            return {
                ...state,
                color: response.results.message.length ? response.results.color : '',
                message: messageResponse,
                colorEdit: response.results.message.length ? response.results.color : '',
                messageEdit: messageResponse
            }

        case types.SAVE_BANNER:
            return {
                ...state,
                isLoading: true
            }

        case types.SAVE_BANNER_ERROR:
            return {
                ...state,
                isLoading: false
            }

        case types.SAVE_BANNER_SUCCESS:
            return {
                ...state,
                color: state.colorEdit,
                message: state.messageEdit,
                isLoading: false
            }

        case types.DELETE_BANNER_SUCCESS:
            return {
                ...state,
                color: '',
                message: '',
                colorEdit: '',
                messageEdit: ''
            }

        case types.DELETE_BANNER_ERROR:
            return {
                ...state,
                isLoading: false
            }

        default:
            return state;
        }
}

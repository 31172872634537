import * as types from "./types";

/* State shape
state: {
    isLoadingGet/Save/Delete: boolean,
    availableConferences: arr,
    availableAccessPoints: arr,
    reservedConferences: arr,
    locations: arr,
    terminateEntities: arr
}
*/

let initialState = {
    isLoadingGet: true,
    isLoadingSave: false,
    isLoadingDelete: false,
    availableConferences: [],
    availableAccessPoints: [],
    reservedConferences: [],
    locations: [],
    terminateEntities: []
}

function getLocations(entities) {
    
    let locations = entities.filter(function (entity) {
        return entity.vmStatus === 'Active';
        }).reduce((prev, next) => {
        let isIn = prev.some(loc => loc === next.regionName);
        
        if(!isIn) {
            prev.push(next.regionName);
        }

        return prev;
    }, []);

    return locations;
}

export default function reservationReducer(state = initialState, action) {
    switch(action.type) {
        case types.GET_CONFERENCES:
            return {
                ...state,
                // isLoadingGet: true
            }

        case types.GET_CONFERENCES_SUCCESS:
            const results = action.response.results;

            return {
                ...state,
                isLoadingGet: false,
                availableConferences: results.available.jitsi,
                availableAccessPoints: results.available.rim,
                reservedConferences: results.reservations,
                locations: getLocations(results.available.jitsi)
            }

        case types.GET_CONFERENCES_ERROR:
            return {
                ...state,
                isLoadingGet: false
            }

        case types.RESERVE:
            return {
                ...state,
                isLoadingSave: true
            }

        case types.RESERVE_SUCCESS:
            
            return {
                ...state,
                isLoadingSave: false
            }

        case types.RESERVE_ERROR:
            return {
                ...state,
                isLoadingSave: false
            }

        case types.TERMINATE:
            return {
                ...state,
                isLoadingDelete: true,
                terminateEntities: [...state.terminateEntities, action.tag]
            }

        case types.TERMINATE_SUCCESS:
            let index = state.terminateEntities.findIndex(e => e === action.tag);

            return {
                ...state,
                isLoadingDelete: false,
                terminateEntities: [
                    ...state.terminateEntities.slice(0, index),
                    ...state.terminateEntities.slice(index + 1)
                ]
            }

        case types.TERMINATE_ERROR:
            return {
                ...state,
                isLoadingDelete: false,
            }

        default:
            return state;
        }
}
import * as types from "./types";

let initialState = {
    maxValue: 5,
    isLoading: false,
    type: '', 
    typeEdit: '',
    value: 0,
    valueEdit: 0
}

export default function accessPointMaxReducer(state = initialState, action) {
    const response = action.response;
    switch(action.type) {
        case types.GET_MAX_SUCCESS:
            return {
                ...state,
                value: response.results.access_point_max,
                type: response.results.access_point_max_type,
                valueEdit: response.results.access_point_max,
                typeEdit: response.results.access_point_max_type
            }

        case types.GET_MAX_ERROR:
            return {
                ...state,
                isLoading: false
            }

        case types.RESET_MAX:
            return {
                ...state,
                valueEdit: state.value,
                typeEdit: state.type
            }

        case types.UPDATE_MAX:
            return {
                ...state,
                valueEdit: action.valueEdit,
                typeEdit: action.typeEdit
            }

        case types.SAVE_MAX:
            return {
                ...state,
                isLoading: true
            }

        case types.SAVE_MAX_SUCCESS:
            return {
                ...state,
                isLoading: false,
                value: state.valueEdit,
                type: state.typeEdit
            }

        case types.SAVE_MAX_ERROR:
            return {
                ...state,
                isLoading: false
            }

        default:
            return state;
        }
}

import React, {useState, useEffect} from 'react';
import moment from 'moment';
import PropTypes from "prop-types";

function CountDown({ expiration }) {
    const [timeLeft, setTime] = useState(onSetTime);
    const timer = () => {
        let time = onSetTime();
        setTime(time)
    };

    useEffect(
        () => {
            if(expiration) {
                const id = setInterval(timer, 1000);
                if(timeLeft === "0:00:00") {
                    clearInterval(id);
                }
                return () => clearInterval(id);
            }
        },
        [timeLeft]
    )

    function checkForSingleNumber(value) {
        if(value < 10) {
            return `0${value}`;
        }
        return value;
    }

    function onSetTime() {

        var startTime = moment();
        var endTime = moment.utc(expiration, 'YYYY-MM-DD HH:mm:ss.SSS');

        // calculate total duration
        var duration = moment.duration(endTime.diff(startTime));

        // duration in hours
        var hours = parseInt(duration.asHours());

        // duration in minutes
        var minutes = checkForSingleNumber(parseInt(duration.asMinutes())%60);

        // duration in minutes
        var seconds = checkForSingleNumber(parseInt(duration.asSeconds())%60);

        return `${hours}:${minutes}:${seconds}`;
    
    }

    return <span className="timer">{timeLeft}</span>
}

CountDown.defaultProps = {
    expiration: ''
}

CountDown.propTypes = {
    expiration: PropTypes.string
};

export default CountDown;
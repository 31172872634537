export function authRequest(endpoint, params) {
    return fetch(endpoint, params)
        .then(response => response.json())
        .then(json => {
            const { errorCode } = json.results || {};
            // Do we have an error? Are we currently loading 2fa?
            if(json.success === false && errorCode !== 432) {
                throw new Error(json.message); 
            }

            // We know this is just 2FA so pass it back to the view
            return json;
        })
        .catch(err => {
            throw new Error(err.message);
        })
}

export default function serviceRequest(endpoint, params) {
    return fetch(endpoint, params)
    .then(response => {
        let resp = response.json();
        if(response.ok) {
            return resp;
        }
        throw new Error(response.status);
    })
    .then(json => {
        if(!json.success) {
            throw new Error(json.message); 
        }
        return json;
    })
    .catch(err => {
        throw new Error(err.message);
    })
}
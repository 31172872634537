import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Collapse from '@material-ui/core/Collapse';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme) => ({
    text: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    nested: {
      paddingLeft: theme.spacing(2),
      fontSize: '0.9em',
      paddingTop: '10px',
    },
    input: {
        color: 'black !important',
        flexGrow: 1,
        padding: '0 5px',
        fontSize: '0.9em !important',
    },
    button: {
        height: '0.7em',
        width: '0.7em',
    }
  }));

export default function Credentials({open, username, password}) {
    const classes = useStyles();
    const [show, setState] = useState(false);

    const handleClickShowPassword = () => {
        setState(!show);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickCopy = (value) => {
        navigator.clipboard.writeText(value);
    }
    
    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <div className={classes.nested}>
                <div className={classes.text}>
                    <b>Username: </b> 
                    <Input
                        disabled
                        disableUnderline
                        className={classes.input}
                        type='text'
                        value={username}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    fontSize="small"
                                    aria-label="copy"
                                    onClick={() => handleClickCopy(username)}
                                >
                                    <FileCopyIcon className={classes.button}/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </div>
                <div className={classes.text}>
                    <b>Password: </b> 
                    <Input
                        disabled
                        disableUnderline
                        className={classes.input}
                        type={show ? 'text' : 'password'}
                        value={password}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    fontSize="small"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {show ? <Visibility className={classes.button}/> : <VisibilityOff className={classes.button}/>}
                                </IconButton>
                                <IconButton
                                    fontSize="small"
                                    aria-label="copy"
                                    onClick={() => handleClickCopy(password)}
                                >
                                    <FileCopyIcon className={classes.button}/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </div>
            </div>
        </Collapse>
    )
}

Credentials.propTypes = {
    open: PropTypes.bool,
    username: PropTypes.string,
    password: PropTypes.string
}
import { config } from "../../../../config";
import serviceRequest from '../utils';
const { namespace, secureUrl } = config;
const ACCESS_POINT_MAX_ENDPOINT = `${secureUrl}/${namespace}/accesspointmax`;

export const getAccessPointMaxService = () => {

    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        }
    }
    return serviceRequest(ACCESS_POINT_MAX_ENDPOINT, parameters);
}


export const saveAccessPointMaxService = (request) => {

    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        },
        body: JSON.stringify({
            access_point_max: request.payload.value,
            access_point_max_type: request.payload.type
        })
    }
    return serviceRequest(ACCESS_POINT_MAX_ENDPOINT, parameters);
}
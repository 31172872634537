export const RESET_BANNER = 'RESET_BANNER';
export const UPDATE_BANNER = 'UPDATE_BANNER';

export const SAVE_BANNER = 'SAVE_BANNER';
export const SAVE_BANNER_SUCCESS = 'SAVE_BANNER_SUCCESS';
export const SAVE_BANNER_ERROR = 'SAVE_BANNER_ERROR';

export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_ERROR = 'GET_BANNER_ERROR';

export const DELETE_BANNER = 'DELETE_BANNER';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR';

export default {
    RESET_BANNER,
    UPDATE_BANNER,
    SAVE_BANNER,
    SAVE_BANNER_SUCCESS,
    SAVE_BANNER_ERROR,
    GET_BANNER,
    GET_BANNER_SUCCESS,
    GET_BANNER_ERROR,
    DELETE_BANNER,
    DELETE_BANNER_SUCCESS,
    DELETE_BANNER_ERROR
};
import { config } from "../../../../config";
const { namespace, secureUrl } = config;
import serviceRequest from '../utils';
const BANNER_ENDPOINT = `${secureUrl}/${namespace}/banner`;

export const getBannerService = () => {

    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        }
    }

    return serviceRequest(BANNER_ENDPOINT, parameters);
}


export const saveBannerService = (request) => {

    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        },
        body: JSON.stringify({
            color: request.color,
            message: request.message
        })
    }

    return serviceRequest(BANNER_ENDPOINT, parameters);
}

export const deleteBannerService = (request) => {

    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        },
        body: JSON.stringify({
            message: ''
        })
    }

    return serviceRequest(BANNER_ENDPOINT, parameters);
}
import React  from 'react';
import Toolbar from '../toolbar/toolbar';
import BannerAlert from '../../pages/banner/bannerAlert';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { config } from '../../../../config';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        paddingTop: '10px',
        flexGrow: 1,
        boxShadow: 'none'
    },
}));

export default function Template(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <BannerAlert />
            <Grid container direction="column" spacing={2}>
                <Grid xs item>
                    <Toolbar />
                </Grid>
                <Grid xs item>
                    <Paper className={classes.paper}>
                        {props.children}
                    </Paper>
                </Grid>
            </Grid>
            <div className="version-number">{config.version}</div>
        </React.Fragment>
    )
}
import * as types from './types';
import createAlert from "./operations";

export default function alerts(state = [], action) {
    const { payload, type } = action;
  
    switch (type) {
      case types.ADD_ALERT:
        let obj = createAlert(payload);
        return [obj];

      case types.REMOVE_ALERT:
        return state.filter(alert => alert.id !== payload);

      default:
        return state;
    }
  }
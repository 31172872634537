import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router'
import createRootReducer, { rootSaga }  from './ducks';

export const history = createBrowserHistory({ basename: '/meetings' });

const configureStore = () => {
    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

    const sagaMiddleware = createSagaMiddleware();
    
    const appReducer = createRootReducer(history);
    const rootReducer = (state, action) => {
        if(action.type === 'RESET_APP') {
            state = undefined;
        }
    
        return appReducer(state, action);
    }

    const enhancer = composeEnhancers(
        applyMiddleware(
            sagaMiddleware,
            routerMiddleware(history)
        )
    )

    return {
        ...createStore(rootReducer, enhancer),
        runSaga: sagaMiddleware.run(rootSaga)
    }
};

export default configureStore;
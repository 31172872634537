import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TvIcon from '@material-ui/icons/Tv';
import Typography from '@material-ui/core/Typography';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        flexGrow: 1,
        boxShadow: 'none',
        margin: '0 5px'
    },
    h3: {
        color: '#689f38',
    },
    subtitle2: {
        color: '#616161',
        fontSize: '12px',
        maxHeight: '20px'
    }
  }));

function InfoPanel({ reservation: { availableConferences = [], availableAccessPoints = [], isLoadingGet} }) {
    const classes = useStyles();
    let conferences = availableConferences.filter(conf => conf.status === 'active');
    let accessPoints = availableAccessPoints.filter(a => a.status === 'active');
    let isBuilding = availableConferences.some(conf => conf.status != 'active');
    let apIsBuilding = availableAccessPoints.some(point => point.status != 'active');
    let infoPanels = [{
        building: isBuilding,
        entities: conferences,
        name: 'Conferences'
    }, {
        building: apIsBuilding,
        entities: accessPoints,
        name: 'Access Points'
    }]

    return (
        <div className={classes.root}>
            <Grid container direction="row" justify="space-between">
                {infoPanels.map((panel, i) => {
                    return (
                        <Paper key={i} xs={6} className={classes.paper}>
                            <Grid container direction="row" justify="space-between" alignItems="flex-end">
                                <Grid item xs={10}>
                                    <Typography variant="subtitle2" align="left" className={classes.subtitle2}>
                                        {panel.building ? 
                                            <span><AutorenewSharpIcon className="spinner"></AutorenewSharpIcon> 
                                                <span style={{position: 'relative', top: '-11px'}}>{panel.name} building...</span>
                                            </span> 
                                        : 
                                            isLoadingGet ? <Skeleton animation="wave" /> :
                                            !panel.entities.length ? `No Available ${panel.name}` : `Current Available ${panel.name}`
                                        } 
                                    </Typography>
                                    <Typography variant="h3" align="left" className={classes.h3}>
                                        {isLoadingGet ? <Skeleton animation="wave" variant="rect" width={40} height={45}/> : panel.entities.length}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {isLoadingGet ? <Skeleton animation="wave" variant="circle" width={30} height={30} /> : <TvIcon /> }
                                </Grid>
                            </Grid>
                        </Paper>
                    )
                })}
            </Grid>
        </div>
    )
}

const mapStateToProps = state => ({
    reservation: state.reservationState
});

export default connect(mapStateToProps)(InfoPanel);
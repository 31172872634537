import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// Import sagas
import {all} from 'redux-saga/effects';
import authSagas from './auth/sagas';
import reservationSagas from './reservation/sagas';
import bannerSagas from './banner/sagas';
import accessPointMaxSagas from './accesspointmax/sagas';
// import totpQRCodeSagas from './totpqrcode/sagas';

// Import reducers
import authState from './auth';
import bannerState from './banner';
import accessPointMaxState from './accesspointmax';
import alertState from './alerts';
import reservationState from './reservation';
// import totpQRCodeState from './totpqrcode';

export const resetAction = () => {
    return {
      type: 'RESET_APP'
    }
};

// Export root Saga for store to consume
export function* rootSaga() {
    yield all([
        ...authSagas,
        ...reservationSagas,
        ...bannerSagas,
        ...accessPointMaxSagas
    ])
}

// Export a createRootReducer function.  Store will pass history object to connect router.
const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    authState, bannerState, accessPointMaxState, alertState, reservationState
})

export default createRootReducer;


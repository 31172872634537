import * as types from './types';

export function addAlertAction(payload= {}) {
    return {
      payload,
      type: types.ADD_ALERT
    };
  }
  
  export function removeAlertAction(id) {
    return {
      payload: id,
      type: types.REMOVE_ALERT
    };
  }
import { call, put, takeLatest, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router'
import * as services from './services';
import * as types from './types';
import * as alerts from '../alerts/types';


// function* notSagaPrepareUser(email, response) {
//     response['email'] = email;

//     localStorage.removeItem('meetings');
//     localStorage.setItem('meetings', response.results.token);

//     const isAdminResponse = yield call(services.userService);
//     console.log('RESPONSE FROM USER', isAdminResponse)
//     response['isAdmin'] = isAdminResponse.results.is_admin;
//     localStorage.setItem('isAdmin', response['isAdmin']);
//     response['twoFactorAuth'] = isAdminResponse.results.twoFactorAuth;
//     localStorage.setItem('twoFactorAuth', isAdminResponse.results.twoFactorAuth);
//     localStorage.setItem('loggedInUser', email);

//     yield put({ type: types.LOGIN_USER_SUCCESS, response });
// }

function* loginSaga(payload) {
    try {
        //Temporary code to clean up local storage
        localStorage.removeItem('loggedInUser');
        localStorage.removeItem('twoFactorAuth');
        localStorage.removeItem('isAdmin');

        const response = yield call(services.loginUserService, payload);
        const { errorCode } = response.results;

        if (errorCode && errorCode === 432) {
            yield put({ type: types.TWOFA_USER, response });
        } else {
            localStorage.removeItem('meetings');
            localStorage.setItem('meetings', response.results.token);

            yield put({ type: types.LOGIN_USER_SUCCESS, response });
        }
    } catch(error) {
        yield put({ type: types.LOGIN_USER_ERROR, error });

        const failedPayload = {
          text: "Unsuccessful Login. Please try again.",
          severity: "error"
        }

        yield put({ type: alerts.ADD_ALERT, payload: failedPayload } );
    }
}

function* twofaVerifySaga(payload) {
    try {
        const response = yield call(services.loginUserService, payload);

        localStorage.removeItem('meetings');
        localStorage.setItem('meetings', response.results.token);

        yield put({ type: types.TWOFA_USER_VERIFY_SUCCESS, response });
        yield put({ type: types.LOGIN_USER_SUCCESS, response });

    } catch(error) {
        yield put({ type: types.TWOFA_USER_VERIFY_ERROR, error })

        const failedPayload = {
          text: String(error || 'There was an error verifying your code'),
          severity: "error"
        }

        yield put({ type: alerts.ADD_ALERT, payload: failedPayload } );

    }
}

function* getUserSaga() {
    try {
        const response = yield call(services.userService);
        yield put({ type: types.GET_USER_SUCCESS, response });

    } catch(error) {
        yield put({ type: types.GET_USER_ERROR, error })
    }
}

function* logoutSaga() {
    try {
        const response = yield call(services.logoutUserService);
        yield put({ type: types.LOGOUT_USER_SUCCESS, response });

        const successPayload = {
          text: "Logout Successful"
        }

        yield put({ type: alerts.ADD_ALERT, payload: successPayload } );
        yield put(push('/login'))
        yield put({ type: 'RESET_APP'});

    } catch(error) {
        const failedPayload = {
            text: "Unsuccessful Logout. Please try again.",
            severity: "error"
        }
        
        yield put({ type: alerts.ADD_ALERT, payload: failedPayload } );
    }
}

function* changeUserPasswordSaga(payload) {
    try {
        const response = yield call(services.changeUserPasswordService, payload);
        yield put({ type: types.CHANGE_USER_PASSWORD_SUCCESS, response });

        yield put(push('/login'))
        yield put({ type: 'RESET_APP'});
       
    } catch(error) {
        yield put({ type: types.CHANGE_USER_PASSWORD_ERROR, error })

        if(parseInt(error.message) > 400) {
            yield put({ type: 'RESET_APP'});
            localStorage.removeItem('meetings');
            yield put(push('/login', {isSessionExpired: true}))
        } else {
            const failedPayload = {
                text: "User Password Change Failed",
                severity: "error"
            }
            
            yield put({ type: alerts.ADD_ALERT, payload: failedPayload } );
        }
    }
}

function* changeProxyPasswordSaga(payload) {
    try {
        const response = yield call(services.changeProxyPasswordService, payload);
        yield put({ type: types.CHANGE_PROXY_PASSWORD_SUCCESS, response });

        const successPayload = {
            text: "Proxy Password Change Successful"
        }
        yield put({ type: alerts.ADD_ALERT, payload: successPayload } );

    } catch(error) {
        yield put({ type: types.CHANGE_PROXY_PASSWORD_ERROR, error })

        if(parseInt(error.message) > 400) {
            yield put({ type: 'RESET_APP'});
            localStorage.removeItem('meetings');
            yield put(push('/login', {isSessionExpired: true}))
        } else {
            const failedPayload = {
                text: "Proxy Password Change Failed",
                severity: "error"
            }
              
            yield put({ type: alerts.ADD_ALERT, payload: failedPayload } );
        }
    }
}

function* getTwoFactorAuthTokenSaga() {
    try {
        const response = yield call(services.getTwoFactorAuthTokenService);
        yield put({ type: types.GET_TWO_FACTOR_AUTHENTICATION_TOKEN_SUCCESS, response });

    } catch(error) {
        yield put({ type: types.GET_TWO_FACTOR_AUTHENTICATION_TOKEN_ERROR, error })

        if(parseInt(error.message) > 400) {
            yield put({ type: 'RESET_APP'});
            localStorage.removeItem('meetings');
            yield put(push('/login', {isSessionExpired: true}))
        } else {
            const failedPayload = {
                text: "Get Two Factor Auth Token Failed",
                severity: "error"
            }
              
            yield put({ type: alerts.ADD_ALERT, payload: failedPayload } );
        }
    }
}

function* verifyTwoFactorAuthChallengeSaga(payload) {
    try {
        const response = yield call(services.verifyTwoFactorAuthChallengeService, payload);
        yield put({ type: types.VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE_SUCCESS, response });
        yield put ({ type: types.UPDATE_TWO_FACTOR_AUTHENTICATION_CHANGING_STATE});

        const successPayload = {
            text: "Verify Two Factor Auth Challenge Successful"
        }
        yield put({ type: alerts.ADD_ALERT, payload: successPayload } );

    } catch(error) {
        yield put({ type: types.VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE_ERROR, error })

        if(parseInt(error.message) > 400) {
            yield put({ type: 'RESET_APP'});
            localStorage.removeItem('meetings');
            yield put(push('/login', {isSessionExpired: true}))
        } else {
            const failedPayload = {
                text: "Verify Two Factor Auth Challenge Failed",
                severity: "error"
            }
              
            yield put({ type: alerts.ADD_ALERT, payload: failedPayload } );
        }
    }
}

function* deleteTwoFactorAuthSaga(payload) {
    try {
        const response = yield call(services.deleteTwoFactorAuthService, payload);
        yield put({ type: types.DELETE_TWO_FACTOR_AUTHENTICATION_SUCCESS, response });

        const successPayload = {
            text: "Delete Two Factor Authentication Successful"
        }
        yield put({ type: alerts.ADD_ALERT, payload: successPayload } );
        // localStorage.setItem('twoFactorAuth', 'false');

    } catch(error) {
        yield put({ type: types.DELETE_TWO_FACTOR_AUTHENTICATION_ERROR, error })

        if(parseInt(error.message) > 400) {
            yield put({ type: 'RESET_APP'});
            localStorage.removeItem('meetings');
            yield put(push('/login', {isSessionExpired: true}))
        } else {
            const failedPayload = {
                text: "Delete Two Factor Authentication Failed",
                severity: "error"
            }
              
            yield put({ type: alerts.ADD_ALERT, payload: failedPayload } );
        }

        
    }
}

function* watchUserAuthentication() {
    yield takeLatest(types.LOGIN_USER, loginSaga),
    yield takeLatest(types.TWOFA_USER_VERIFY, twofaVerifySaga),
    yield takeLatest(types.LOGOUT_USER, logoutSaga),
    yield takeLatest(types.GET_USER, getUserSaga),
    yield takeLatest(types.CHANGE_USER_PASSWORD, changeUserPasswordSaga),
    yield takeLatest(types.CHANGE_PROXY_PASSWORD, changeProxyPasswordSaga),
    yield takeLatest(types.GET_TWO_FACTOR_AUTHENTICATION_TOKEN, getTwoFactorAuthTokenSaga),
    yield takeLatest(types.VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE, verifyTwoFactorAuthChallengeSaga),
    yield takeLatest(types.DELETE_TWO_FACTOR_AUTHENTICATION, deleteTwoFactorAuthSaga)
}

const authSagas = [
    fork(watchUserAuthentication)
];

export default authSagas;



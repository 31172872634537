import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const primary = '#1976d2';
const primaryHover = '#1565c0';
const secondary = '#fff';
const secondaryHover = '#fafafa';
const error = '#d32f2f';
const errorHover = '#c62828';
const cancel = '#616161';
const cancelHover = '#424242';

export const PrimaryButton = withStyles({
    root: {
        textTransform: 'none',
        fontSize: 16,
        backgroundColor: primary,
        color: secondary,
        '&:hover': {
            backgroundColor: primaryHover
        }
    }
})(Button);

export const SecondaryButton = withStyles({
    root: {
        textTransform: 'none',
        fontSize: 16,
        backgroundColor: secondary,
        color: primary,
        padding: '2px 15px',
        '&:hover': {
            backgroundColor: secondaryHover
        }
    }
})(Button);

export const ErrorButton = withStyles({
    root: {
        textTransform: 'none',
        fontSize: 16,
        backgroundColor: error,
        color: secondary,
        '&:hover': {
            backgroundColor: errorHover
        }
    }
})(Button);

export const CancelButton = withStyles({
    root: {
        textTransform: 'none',
        fontSize: 16,
        backgroundColor: cancel,
        color: `${secondary} !important` ,
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: cancelHover,
            textDecoration: 'none'
        }
    }
})(Button);

export const DefaultButton = withStyles({
    root: {
        textTransform: 'none',
        fontSize: 12,
        color: primary
    }
})(Button);
let id = 0;

const defaultOptions = {
    severity: 'success',
    timeout: 5000
};

export default function createAlert(options) {
    return {
        ...defaultOptions,
        ...options,
        id: id++
    }
}
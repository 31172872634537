import types from "./types";

export const resetBannerAction = () => {
    return {
        type: types.RESET_BANNER
    }
};

export const updateBannerAction = ({ color, message }) => {
    return {
        type: types.UPDATE_BANNER,
        color,
        message
    }
};

export const getBannerAction = () => {
    return {
        type: types.GET_BANNER
    }
};

export const saveBannerAction = ({ color, message }) => {
    return {
        type: types.SAVE_BANNER,
        color,
        message
    }
};

export const deleteBannerAction = () => {
    return {
        type: types.DELETE_BANNER
    }
};


export default {
    getBannerAction,
    saveBannerAction,
    deleteBannerAction,
    resetBannerAction,
    updateBannerAction
}
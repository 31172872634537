import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LaunchIcon from '@material-ui/icons/Launch';

import { addAlertAction } from "../../../state/ducks/alerts/actions";

const styles = {
    paddingRight: '75px',
    wordWrap: 'break-word'
}

const regionStyles = {
    display: 'block', 
    marginBottom: '-16px',
    marginTop: '-16px'
}

const QrCodeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#686868">
        <path d="M520-120v-80h80v80h-80Zm-80-80v-200h80v200h-80Zm320-120v-160h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM180-660h120v-120H180v120Zm-60 60v-240h240v240H120Zm60 420h120v-120H180v120Zm-60 60v-240h240v240H120Zm540-540h120v-120H660v120Zm-60 60v-240h240v240H600Zm80 480v-120h-80v-80h160v120h80v80H680ZM520-400v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z"/>
    </svg>
);

class AccessPointList extends Component {

    // shouldComponentUpdate() {
    //     return false;
    // }

    onClickCopy(point) {
        navigator.clipboard.writeText(point)
        this.props.actions.addAlertAction({
            text: `copied to clipboard ${point}`
        })
    }

    render() {
        let { accessPoints } = this.props;
        let { conferenceId } = this.props;

        const list = accessPoints.map((point, i) => {
            let conferenceData = { point: point, conferenceId: conferenceId };
            return (
                <ListItem key={i} disableGutters={true}>
                    <ListItemText style={styles}>
                        <div style={regionStyles}>
                            <p>
                                {point.regionName}
                            </p>
                        </div>
                        <a href={point.url} target="_blank" style={{textDecoration: 'underline', color: '#1976d2', fontSize: '0.95rem'}}>
                            {point.url}
                        </a>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        {conferenceData.point.userProxyUrlMeta.twofa && (conferenceData.point.userProxyUrlMeta.twofa.uri != null && conferenceData.point.userProxyUrlMeta.twofa.uri != undefined) && conferenceData.point.userProxyUrlMeta.twofa.uri && (
                            <Link to={{ pathname: "/totpQRCode", state: { conferenceData: conferenceData } }}>
                                <Tooltip title="TOTP Secret" aria-label="qrCode">
                                    <IconButton edge="end" aria-label="qrCode">
                                        <QrCodeIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        )}
                        <Tooltip title="Copy" aria-label="copy">
                            <IconButton edge="end" aria-label="copy" onClick={() => this.onClickCopy(point.url)}>
                                <FileCopyIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Launch" aria-label="launch">    
                            <IconButton edge="end" aria-label="launch" onClick={() => window.open(point.url)}>
                                <LaunchIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
            )
        })

        return (
            <List style={{maxHeight: '250px', overflowY: 'auto', marginBottom: '5px', paddingTop: '0'}}>
                {list}
            </List>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ 
        addAlertAction }, 
    dispatch)
});

export default connect(null, mapDispatchToProps)(AccessPointList);
import PropTypes from "prop-types";
import React, { Component } from "react";
import Alert from '@material-ui/lab/Alert';


class AlertContainer extends Component {
    componentDidMount() {
      const self = this;

      setTimeout(function() {
        self.props.onDismissClick();
      }, self.props.timeout)
    }

    render() {
      return (
          <Alert variant="filled" severity={this.props.severity} onClose={this.props.onDismissClick}>
              {this.props.text}
          </Alert>
      );
    }
  
    shouldComponentUpdate() {
      return false;
    }
  }
  
AlertContainer.propTypes = {
    severity: PropTypes.string,
    onDismissClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export default AlertContainer;
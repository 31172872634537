import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { config } from '../../../config';

import { getConferencesAction } from '../../state/ducks/reservation/actions';
import { getBannerAction } from '../../state/ducks/banner/actions';
import { getMaxAction } from '../../state/ducks/accesspointmax/actions';
import { getUserAction } from '../../state/ducks/auth/actions';


function Initial(props) {
    let { actions: { getUserAction, getConferencesAction, getBannerAction, getMaxAction }, loading } = props;
    
    useEffect(() => {
        //This runs only once becuase not passing an argument
        getConferencesAction();
        getBannerAction();
        getMaxAction();
        getUserAction();
    }, []);

    useInterval(() => {
        //useInterval will run every 30 seconds, unless reservation is loading then it will skip next interval.
        //Implementing it this way b/c i was seeing long load times and didn't want to make another request if prev still loading.
        getConferencesAction();
        
    }, loading ? null : config.poll)

    return props.children;
}

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

//Gather relevant state from store
const mapStateToProps = state => ({
    loading: state.reservationState.isLoadingGet
})

//Gather relevant actions
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ 
        getConferencesAction,
        getBannerAction, 
        getMaxAction,
        getUserAction }, 
    dispatch)
});

//Inject into functional component
export default connect(mapStateToProps, mapDispatchToProps)(Initial);
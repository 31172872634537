import { config } from "../../../../config";
import serviceRequest from '../utils';
const { namespace, secureUrl } = config;
const CONFERENCES_ENDPOINT = `${secureUrl}/${namespace}/conference`;


export const getConferencesService = () => {

    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        }
    }
    return serviceRequest(CONFERENCES_ENDPOINT, parameters);

}


export const reserveService = (request) => {

    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        },
        body: JSON.stringify({
            project: request.project,
            accessCount: request.accessCount,
            jitsiPile: request.pileId,
            description: request.description
        })
    }
    return serviceRequest(CONFERENCES_ENDPOINT, parameters);

}

export const deleteService = (request) => {

    const parameters = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        },
        body: JSON.stringify({
            tag: request.tag
        })
    }
    return serviceRequest(CONFERENCES_ENDPOINT, parameters);

}
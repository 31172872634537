import React from 'react';
import { Route, Redirect } from "react-router-dom";

function isLoggedIn() {
    if(localStorage.getItem('meetings')) {
        return true;
    }

    return false;
}

export default function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ path }) =>
          isLoggedIn() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: path }
              }}
            />
          )
        }
      />
    );
  }
import { combineReducers } from "redux";
import * as types from "./types";

/* State shape
state: {
  isAuthenticated: boolean,
  loggedInUser: object,
  isLoading: boolean,
  token: string,
  email:string
}
*/

let initialState = {
    isAuthenticated: false,
    loggedInUser: null,
    isLoading: false,
    needsVerifying: false,
    isVerifying: false,
    isAdmin: false,
    twoFactorEnabled: "off",
    token: null,
    twoFactorAuthUri: "",
    twoFactorAuthSecret: "",
    isNotChangingTwoFactorAuthentication: true,
    email: null
}

function getSecret(uri) {

    let secret = (new RegExp('secret=([^]*)'))
                    .exec(uri)
                    .slice(-1)[0];
    return secret;
}

export default function authReducer(state = initialState, action) {
    const response = action.response;
    switch(action.type) {
        case types.LOGIN_USER: {
            return {
                ...state,
                isLoading: true
            };
 
        }
        case types.TWOFA_USER: {
            return {
                ...state,
                needsVerifying: true
            };
 
        }
        case types.TWOFA_USER_VERIFY: {
            return {
                ...state,
                isVerifying: true
            };
 
        }
        case types.TWOFA_USER_VERIFY_ERROR: {
            return {
                ...state,
                isVerifying: false,
                isLoading: false,
            };
 
        }
        case types.TWOFA_USER_VERIFY_SUCCESS: {
            return {
                ...state,
                isVerifying: false,
                needsVerifying: false,
                isAdmin: response.results.is_admin,
                twoFactorEnabled: response.results.twoFactorAuth ? "on" : "off"
            };
        }
        case types.TWOFA_USER_CANCEL: {
            return {
                ...state,
                isVerifying: false,
                isLoading: false,
                needsVerifying: false,
            };
 
        }
        case types.LOGIN_USER_SUCCESS:
            return {
                ...state,
                isAuthenticated: response.success,
                token: response.results.token,
                isLoading: false
            }

        case types.LOGIN_USER_ERROR:
            return {
                ...state,
                isLoading: false
            }

        case types.GET_USER_SUCCESS:
            return {
                ...state,
                isAdmin: response.results.is_admin,
                twoFactorEnabled: response.results.twoFactorAuth ? "on" : "off",
                email: response.results.email
            }
        case types.GET_USER_ERROR:
            return {
                ...state,
            }
        case types.LOGOUT_USER_SUCCESS:
            localStorage.removeItem('meetings');
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                email: null
            }

        case types.CHANGE_USER_PASSWORD: 
            return {
                ...state,
                isLoading: true
            };
    
        case types.CHANGE_USER_PASSWORD_SUCCESS:
            localStorage.removeItem('meetings');
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                token: null,
                email: null
            }

        case types.CHANGE_USER_PASSWORD_ERROR:
            return {
                ...state,
                isLoading: false
            }

        case types.CHANGE_PROXY_PASSWORD: 
            return {
                ...state,
                isLoading: true
            };
    
        case types.CHANGE_PROXY_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case types.CHANGE_PROXY_PASSWORD_ERROR:
            return {
                ...state,
                isLoading: false
            }

        case types.GET_TWO_FACTOR_AUTHENTICATION_TOKEN: 
            return {
                ...state,
                isLoading: true
            }
    
        case types.GET_TWO_FACTOR_AUTHENTICATION_TOKEN_SUCCESS:
            const results = action.response.uri;

            return {
                ...state,
                isLoading: false,
                twoFactorAuthUri: results,
                twoFactorAuthSecret: getSecret(results),
            }

        case types.GET_TWO_FACTOR_AUTHENTICATION_TOKEN_ERROR:
            return {
                ...state,
                isLoading: false,
                isNotChangingTwoFactorAuthentication: true
            }

        case types.VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE: 
            return {
                ...state,
                isLoading: true
            };
    
        case types.VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                twoFactorEnabled: "on"
            }

        case types.VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE_ERROR:
            return {
                ...state,
                isLoading: false
            }

        case types.DELETE_TWO_FACTOR_AUTHENTICATION: 
            return {
                ...state,
                isLoading: true
            };
    
        case types.DELETE_TWO_FACTOR_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isNotChangingTwoFactorAuthentication: true,
                twoFactorEnabled: "off"
            }

        case types.DELETE_TWO_FACTOR_AUTHENTICATION_ERROR:
            return {
                ...state,
                isLoading: false
            }

        case types.UPDATE_TWO_FACTOR_AUTHENTICATION_CHANGING_STATE:
            return {
                ...state,
                isNotChangingTwoFactorAuthentication: !state.isNotChangingTwoFactorAuthentication,
                twoFactorAuthUri: !state.isNotChangingTwoFactorAuthentication  == false ? "" : state.twoFactorAuthUri,
                twoFactorAuthSecret: !state.isNotChangingTwoFactorAuthentication == false ? "" : state.twoFactorAuthSecret,
            }

        case types.RESET_TWO_FA_STATE:
            return {
                ...state,
                isNotChangingTwoFactorAuthentication: true,
                twoFactorUri: '',
                twoFactorAuthSecret: ''
            }

        default:
            return state;
        }
}
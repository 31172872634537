import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Link, useParams } from 'react-router-dom';

import CountDown from '../../layout/countDown/countDown';
import List from './accessPointList';
import Credentials from './credentials';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ErrorButton } from '../../common/buttons/buttons';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Divider from '@material-ui/core/Divider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DefaultButton } from '../../common/buttons/buttons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

import { terminateAction } from '../../../state/ducks/reservation/actions';
import { addAlertAction } from "../../../state/ducks/alerts/actions";

const useStyles = makeStyles((theme) => ({
    h6: {
        fontWeight: 'bold',
        fontSize: '17px',
        textTransform: 'uppercase',
        lineHeight: '27px',
        letterSpacing: '0.05em'
    },
    h5: {
        fontSize: '12px',
        letterSpacing: '0.05em',
        color: '#689F38',
        lineHeight: '27px'
    },
    body2: {
        textDecoration: 'underline',
        color: '#1976d2',
        display: 'flex',
        paddingBottom: '20px'
    },
    subtitle1: {
        fontSize: '12px',
        color: '#616161',
        lineHeight: '20px'
    },
    body1: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '15px',
        fontSize: '0.95rem',
        fontWeight: 'bold',
        letterSpacing: '0.02em',
        cursor: 'pointer',
    },
    dividerFullWidth: {
        margin: '15px 0'
    },
    caption: {
        color: '#616161'
    },
    fontSizeSmall: {
        width: '0.8em',
        height: '0.8em',
        marginRight: '5px'
    }
}));

function Conference({ conferences, loadingDelete, loadingGet, terminateEntities, actions: { terminateAction, addAlertAction } }) {
    let { conferenceId } = useParams();
    const classes = useStyles();
    let conf = conferences.filter(c => c.jitsi.id === parseInt(conferenceId))[0];
    if (conf == null) {
        conf = {
            rims: [],
            jitsi: {
                timeOfDeath: '',
                tag: '',
                speakeasy_password: '',
                speakeasy_user: ''
            }
        }
    }
    let { rims, jitsi: { timeOfDeath, tag, description, regionName } } = conf;
    let user = null;
    let pass = null;
    if(conf.jitsi.meta != null && conf.jitsi.meta.speakeasy_password != null) {
        pass = conf.jitsi.meta.speakeasy_password;
    }
    if(conf.jitsi.meta != null && conf.jitsi.meta.speakeasy_user != null) {
        user = conf.jitsi.meta.speakeasy_user;
    }
    let descriptionDecoded = description != null ? window.atob(description) : '';
    let isTerminating = loadingDelete && terminateEntities.some(e => e === tag);

    const [showCreds, setState] = useState(true);

    const onClickCopyAll = (e) => {
         e.preventDefault();
        let urls = ""
        conf.rims.forEach(function (accessPoint) {
            urls = urls + (accessPoint.regionName + '\r' + accessPoint.url + '\r');
        });
        navigator.clipboard.writeText(urls);
        addAlertAction({
            text: `All access points copied to clipboard`
        });
    }

    const onToggleCredentials = (e) => {
        let newState = !showCreds;
        setState(newState)
    }

    return (
        !loadingGet ? 
            <React.Fragment>
                <Link to="/conferences">
                    <Typography variant="body2" className={classes.body2} align="left">
                        <ArrowBackIcon className={classes.fontSizeSmall}/> <span>Back to Conferences</span>
                    </Typography>
                </Link>
                <Grid container justify="space-between" alignItems="flex-end">
                    <Grid xs={8} item>
                        <Typography variant="h6" component="h1" className={classes.h6} align="left">
                            {regionName} Conference
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle1} align="left">
                            {descriptionDecoded}
                        </Typography>
                    </Grid>
                    <Grid xs={3} item>
                        <Typography variant="h5" component="h5" className={classes.h5} align="right">
                            Time Remaining
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle1} align="right">
                            {timeOfDeath.length ? <CountDown expiration={timeOfDeath} /> : null}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.dividerFullWidth} />
                {user != null &&
                <React.Fragment>
                    <Grid xs={12} item>
                        <Typography variant="body1" className={classes.body1} aria-label="collapse" onClick={onToggleCredentials}>
                            Credentials
                            {showCreds ? 
                                <ExpandLess fontSize="small"/>
                                : 
                                <ExpandMore fontSize="small"/>
                            }
                        </Typography>
                        <Credentials open={showCreds} username={user} password={pass} />
                    </Grid>
                    <Divider className={classes.dividerFullWidth} />
                </React.Fragment>
                }
                <Grid container justify="space-between" alignItems="center">
                    <Grid xs={7} item>
                        <Typography variant="caption" display="block" align="left" className={classes.caption}>
                            <i>Use links below to access conference ({rims.length} total)</i>
                        </Typography>
                    </Grid>
                    <Grid xs={2} item>
                        <DefaultButton onClick={onClickCopyAll}>
                            <FileCopyIcon fontSize="small"/> 
                            <span style={{paddingLeft: '5px'}}>Copy All</span>
                        </DefaultButton>
                    </Grid>
                </Grid>
                <List accessPoints={rims} user={user} pass={pass} conferenceId={conferenceId} />
                <ErrorButton
                    variant="contained"
                    color="secondary"
                    disableElevation
                    fullWidth
                    onClick={() => terminateAction({ tag })}>
                    {isTerminating ?
                        <span><AutorenewSharpIcon className="spinner"></AutorenewSharpIcon> Terminating</span>
                        :
                        <span>Terminate</span>
                    }
                </ErrorButton>
            </React.Fragment>
            :
            <React.Fragment>
                    <Grid container justify="space-between" alignItems="center">
                            <Grid item xs={7}>
                                <Skeleton animation="wave" width="100%" height={40}/>
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton animation="wave" width="100%" height={40} />
                            </Grid>
                    </Grid>
                    <Divider className={classes.dividerFullWidth} />
                    {Array.from(new Array(2)).map((item, index) => (
                        <Grid container justify="space-between" alignItems="center" key={index}>
                            <Grid item xs={7}>
                                <Skeleton animation="wave" width="100%" height={40}/>
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton animation="wave" width="100%" height={40} />
                            </Grid>
                        </Grid>
                    ))}
                    <Skeleton animation="wave" width="100%" height={55} />
                </React.Fragment>
            
    );
}

Conference.defaultProps = {
    conferences: {
        jitsi: {
            timeOfDeath: ''
        },
        rims: []
    }
}

const mapStateToProps = state => ({
    conferences: state.reservationState.reservedConferences,
    loadingDelete: state.reservationState.isLoadingDelete,
    loadingGet: state.reservationState.isLoadingGet,
    terminateEntities: state.reservationState.terminateEntities
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        terminateAction,
        addAlertAction
    },
        dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Conference)

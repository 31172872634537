import { config } from "../../../../config";
import serviceRequest from '../utils';
import { authRequest } from '../utils';
// 
const { namespace, secureUrl, restUrl } = config;
const TWO_FACTOR_AUTHENTICATION_API_ENDPOINT = `${secureUrl}/${namespace}/twofa`;

export const loginUserService = (request) => {
    const LOGIN_API_ENDPOINT = `${restUrl}/${namespace}/login`;

    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request.user)
    }

    // return serviceRequest(LOGIN_API_ENDPOINT, parameters);
    return authRequest(LOGIN_API_ENDPOINT, parameters);
}

export const twofaUserService = (request) => {
    const TWOFA_API_ENDPOINT = `${restUrl}/twofa`;

    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request.user)
    }

    return authRequest(TWOFA_API_ENDPOINT, parameters);
}

export const logoutUserService = () => {
    const LOGOUT_API_ENDPOINT = `${secureUrl}/${namespace}/logout`;

    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        }
    }

    return serviceRequest(LOGOUT_API_ENDPOINT, parameters);
}

export const changeUserPasswordService = (request) => {
    const CHANGE_USER_PASSWORD_API_ENDPOINT = `${secureUrl}/${namespace}/user`;

    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        },
        body: JSON.stringify(request.user)
    }

    return serviceRequest(CHANGE_USER_PASSWORD_API_ENDPOINT, parameters);
}



export const userService = (request) => {
    const IS_USER_ADMIN_ENDPOINT = `${secureUrl}/${namespace}/user`;

    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        }
    }

    return serviceRequest(IS_USER_ADMIN_ENDPOINT, parameters);
}

export const changeProxyPasswordService = (request) => {
    const CHANGE_PROXY_PASSWORD_API_ENDPOINT = `${secureUrl}/${namespace}/user`;

    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        },
        body: JSON.stringify(request.user)
    }

    return serviceRequest(CHANGE_PROXY_PASSWORD_API_ENDPOINT, parameters);
}

export const getTwoFactorAuthTokenService = (request) => {
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        }
    }
    return serviceRequest(TWO_FACTOR_AUTHENTICATION_API_ENDPOINT, parameters);
}

export const verifyTwoFactorAuthChallengeService = (request) => {
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        },
        body: JSON.stringify({
            challenge: request.challenge
        })
    }
    return serviceRequest(TWO_FACTOR_AUTHENTICATION_API_ENDPOINT, parameters);
}

export const deleteTwoFactorAuthService = (request) =>  {
    const parameters = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-Authorization': localStorage.getItem('meetings')
        },
        body: JSON.stringify({
            username: request.username
        })
    }
    return serviceRequest(TWO_FACTOR_AUTHENTICATION_API_ENDPOINT, parameters);
}
export const config = {
    "namespace": "meetings",
    "secureUrl": "/secure/rest/v2",
    "restUrl": "/rest/v2",
    "poll": 30000,
    "version" : "v3.22.13.4",
    "colors": [{
        "color": "blue",
        "hex": "#186BCC"
     }, {
        "color": "green",
        "hex": "#46A60B"
     }, {
        "color": "red",
        "hex": "#CE3A3A"
     } ,{
        "color": "orange",
        "hex": "#DF7C08"
     }]
}
import types from "./types";

export const loginUserAction = (user) => {
  return {
    type: types.LOGIN_USER,
    user
  }
};

export const getUserAction = () => {
  return {
    type: types.GET_USER
  }
};

export const twofaUserAction = (user) => {
  return {
    type: types.TWOFA_USER,
    user
  }
};

export const twofaUserVerifyAction = (user) => {
  return {
    type: types.TWOFA_USER_VERIFY,
    user
  }
};

export const twofaUserCancelAction = () => {
  return {
    type: types.TWOFA_USER_CANCEL
  }
};

export const logoutUserAction = () => {
  return {
    type: types.LOGOUT_USER
  }
};

export const changeUserPasswordAction = (user) => {
  return {
    type: types.CHANGE_USER_PASSWORD,
    user
  }
};

export const changeProxyPasswordAction = (user) => {
  return {
    type: types.CHANGE_PROXY_PASSWORD,
    user
  }
};

export const getTwoFactorAuthTokenAction = () => {
  return {
    type: types.GET_TWO_FACTOR_AUTHENTICATION_TOKEN
  }
};

export const verifyTwoFactorAuthChallengeAction = (challenge) => {
  return {
    type: types.VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE,
    challenge
  }
};

export const deleteTwoFactorAuthAction = (username) => {
  return {
    type: types.DELETE_TWO_FACTOR_AUTHENTICATION,
    username
  }
};

export const updateTwoFactorAuthChangingStateAction = () => {
  return {
    type: types.UPDATE_TWO_FACTOR_AUTHENTICATION_CHANGING_STATE
  }
};

export const reset2faState = () => {
  return {
    type: types.RESET_TWO_FA_STATE
  }
}

export default {
  loginUserAction,
  twofaUserAction,
  twofaUserVerifyAction,
  twofaUserCancelAction,
  logoutUserAction,
  changeUserPasswordAction,
  changeProxyPasswordAction,
  getTwoFactorAuthTokenAction,
  verifyTwoFactorAuthChallengeAction,
  updateTwoFactorAuthChangingStateAction,
  deleteTwoFactorAuthAction,
  reset2faState
}
import { call, put, takeLatest, takeEvery, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as services from './services';
import * as types from './types';
import { ADD_ALERT } from '../alerts/types';

function* getBannerSaga() {
    try {
        const response = yield call(services.getBannerService);
        yield put({ type: types.GET_BANNER_SUCCESS, response });
      
    } catch(error) {
        if(parseInt(error.message) > 400) {
            yield put({ type: 'RESET_APP'});
            localStorage.removeItem('meetings');
            // yield put(push('/login', {isSessionExpired: true}))
          }
    }
  }

function* watchGetBanner() {
    yield takeEvery(types.GET_BANNER, getBannerSaga)
}

function* saveBannerSaga(payload) {
    try {
        payload.message = window.btoa(payload.message);
        const response = yield call(services.saveBannerService, payload);
        if (response.success) {
            yield put({ type: types.SAVE_BANNER_SUCCESS, response });
        } else {
            yield put({ type: types.SAVE_BANNER_ERROR, response });
            throw new Error(response.message);
        }

    } catch(error) {
        console.log(error.message);
        const alertContent = {
            text: `Error saving banner data: ${error.message}`,
            severity: "error"
          }
          yield put({ type: ADD_ALERT, payload: alertContent } );
    }
  }

function* watchSaveBanner() {
    yield takeEvery(types.SAVE_BANNER, saveBannerSaga)
}

function* deleteBannerSaga() {
    try {
        const response = yield call(services.deleteBannerService);
        
        if (response.success) {
            yield put({ type: types.DELETE_BANNER_SUCCESS, response });
        } else {
            yield put({ type: types.DELETE_BANNER_ERROR, response });
            throw new Error(response.message);
        }
      
    } catch(error) {
        console.log(error.message);
        const alertContent = {
            text: `Error saving banner data: ${error.message}`,
            severity: "error"
          }
          yield put({ type: ADD_ALERT, payload: alertContent } );
    }
  }

function* watchDeleteBanner() {
    yield takeEvery(types.DELETE_BANNER, deleteBannerSaga)
}

const bannerSagas = [
    fork(watchGetBanner),
    fork(watchSaveBanner),
    fork(watchDeleteBanner)
  ];
  
  export default bannerSagas;
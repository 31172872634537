export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const TWOFA_USER = 'TWOFA_USER';
export const TWOFA_USER_VERIFY = 'TWOFA_USER_VERIFY';
export const TWOFA_USER_VERIFY_SUCCESS = 'TWOFA_USER_SUCCESS';
export const TWOFA_USER_VERIFY_ERROR = 'TWOFA_USER_ERROR';
export const TWOFA_USER_CANCEL = 'TWOFA_USER_CANCEL';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const USER_IS_ADMIN = 'USER IS ADMINN';
export const USER_IS_NOT_ADMIN = 'USER IS NOT ADMIN';

export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_ERROR = 'CHANGE_USER_PASSWORD_ERROR';

export const CHANGE_PROXY_PASSWORD = 'CHANGE_PROXY_PASSWORD';
export const CHANGE_PROXY_PASSWORD_SUCCESS = 'CHANGE_PROXY_PASSWORD_SUCCESS';
export const CHANGE_PROXY_PASSWORD_ERROR = 'CHANGE_PROXY_PASSWORD_ERROR';

export const GET_TWO_FACTOR_AUTHENTICATION_TOKEN = 'GET_TWO_FACTOR_AUTHENTICATION';
export const GET_TWO_FACTOR_AUTHENTICATION_TOKEN_SUCCESS = 'GET_TWO_FACTOR_AUTHENTICATION_SUCCESS';
export const GET_TWO_FACTOR_AUTHENTICATION_TOKEN_ERROR = 'GET_TWO_FACTOR_AUTHENTICATION_ERROR';

export const VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE = 'VERIFY_TWO_FACTOR_AUTHENTICATION';
export const VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE_SUCCESS = 'VERIFY_TWO_FACTOR_AUTHENTICATION_SUCCESS';
export const VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE_ERROR = 'VERIFY_TWO_FACTOR_AUTHENTICATION_ERROR';

export const DELETE_TWO_FACTOR_AUTHENTICATION = 'DELETE_TWO_FACTOR_AUTHENTICATION';
export const DELETE_TWO_FACTOR_AUTHENTICATION_SUCCESS = 'DELETE_TWO_FACTOR_AUTHENTICATION_SUCCESS';
export const DELETE_TWO_FACTOR_AUTHENTICATION_ERROR = 'DELETE_TWO_FACTOR_AUTHENTICATION_ERROR';

export const UPDATE_TWO_FACTOR_AUTHENTICATION_CHANGING_STATE = 'UPDATE_TWO_FACTOR_AUTHENTICATION_CHANGING_STATE';

export const RESET_TWO_FA_STATE = "RESET_TWO_FA_STATE";

export default {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  TWOFA_USER,
  TWOFA_USER_VERIFY,
  TWOFA_USER_VERIFY_SUCCESS,
  TWOFA_USER_VERIFY_ERROR,
  TWOFA_USER_CANCEL,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  USER_IS_ADMIN,
  USER_IS_NOT_ADMIN,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
  CHANGE_PROXY_PASSWORD,
  CHANGE_PROXY_PASSWORD_SUCCESS,
  CHANGE_PROXY_PASSWORD_ERROR,
  GET_TWO_FACTOR_AUTHENTICATION_TOKEN,
  GET_TWO_FACTOR_AUTHENTICATION_TOKEN_SUCCESS,
  GET_TWO_FACTOR_AUTHENTICATION_TOKEN_ERROR,
  VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE,
  VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE_SUCCESS,
  VERIFY_TWO_FACTOR_AUTHENTICATION_CHALLENGE_ERROR,
  DELETE_TWO_FACTOR_AUTHENTICATION,
  DELETE_TWO_FACTOR_AUTHENTICATION_SUCCESS,
  DELETE_TWO_FACTOR_AUTHENTICATION_ERROR,
  UPDATE_TWO_FACTOR_AUTHENTICATION_CHANGING_STATE,
  RESET_TWO_FA_STATE
};
export const GET_CONFERENCES = 'GET_CONFERENCES';
export const GET_CONFERENCES_SUCCESS = 'GET_CONFERENCES_SUCCESS';
export const GET_CONFERENCES_ERROR = 'GET_CONFERENCES_ERROR';

export const RESERVE = 'RESERVE';
export const RESERVE_SUCCESS = 'RESERVE_SUCCESS';
export const RESERVE_ERROR = 'RESERVE_ERROR';

export const TERMINATE = 'TERMINATE';
export const TERMINATE_SUCCESS = 'TERMINATE_SUCCESS';
export const TERMINATE_ERROR = 'TERMINATE_ERROR';

export default {
    GET_CONFERENCES,
    GET_CONFERENCES_SUCCESS,
    GET_CONFERENCES_ERROR,
    RESERVE,
    RESERVE_SUCCESS,
    RESERVE_ERROR,
    TERMINATE,
    TERMINATE_SUCCESS,
    TERMINATE_ERROR
};
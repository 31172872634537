import { call, put, takeLatest, takeEvery, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router'
import * as services from './services';
import * as types from './types';
import { ADD_ALERT } from '../alerts/types';

function* getConferencesSaga() {
    try {
      const response = yield call(services.getConferencesService);
      yield put({ type: types.GET_CONFERENCES_SUCCESS, response });

    } catch(err) {
      yield put({ type: types.GET_CONFERENCES_ERROR });
     
      if(parseInt(err.message) > 400) {
        yield put({ type: 'RESET_APP'});
        localStorage.removeItem('meetings');
        yield put(push('/login', {isSessionExpired: true}))
      }
      
    }
  }

function* watchGetConferences() {
    yield takeEvery(types.GET_CONFERENCES, getConferencesSaga)
}

function* reserveConferenceSaga(payload) {
  try {

    yield call(services.reserveService, payload);
    yield call(getConferencesSaga);
   
    const alertContent = {
      text: "Success. Conference Reserved."
    }
    yield put({ type: ADD_ALERT, payload: alertContent });
    yield put(push('/conferences'))
    yield put({ type: types.RESERVE_SUCCESS } );
    
  } catch(error) {
    console.log(error.message);
    yield put({ type: types.RESERVE_ERROR });

    if(parseInt(error.message) > 400) {
      yield put({ type: 'RESET_APP'});
      localStorage.removeItem('meetings');
      yield put(push('/login', {isSessionExpired: true}))
    } else {
      const alertContent = {
        text: 'We were unable to reserve the conference room. Please try again.',
        severity: "error"
      }
      yield put({ type: ADD_ALERT, payload: alertContent } );
    }
    
  }
}

function* watchReserveConference() {
    yield takeLatest(types.RESERVE, reserveConferenceSaga)
}

function* terminateConferenceSaga(payload) {
    try {

      yield call(services.deleteService, payload);
      yield call(getConferencesSaga)
     
      const alertContent = {
        text: "Success. Conference Terminated."
      }
      yield put({ type: ADD_ALERT, payload: alertContent } );
      yield put(push('/conferences'))
      yield put({ type: types.TERMINATE_SUCCESS, tag: payload.tag } );
      
    } catch(error) {
      console.log(error.message);
      yield put({ type: types.TERMINATE_ERROR });

      if(parseInt(error.message) > 400) {
        yield put({ type: 'RESET_APP'});
        localStorage.removeItem('meetings');
        yield put(push('/login', {isSessionExpired: true}))
      } else {
        const alertContent = {
          text: 'Unable to terminate conference. Please try again.',
          severity: "error"
        }
        yield put({ type: ADD_ALERT, payload: alertContent } );
      }
      
      
    }
  }
  
  function* watchTerminateConference() {
      yield takeLatest(types.TERMINATE, terminateConferenceSaga)
  }



const reservationSagas = [
  fork(watchGetConferences),
  fork(watchReserveConference),
  fork(watchTerminateConference)
];

export default reservationSagas;

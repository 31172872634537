import types from "./types";

export const resetMaxAction = () => {
    return {
        type: types.RESET_MAX
    }
};

export const updateMaxAction = ({ type, value }) => {
    return {
        type: types.UPDATE_MAX,
        valueEdit: value, 
        typeEdit: type
    }
}

export const getMaxAction = () => {
    return {
        type: types.GET_MAX,
    }
};

export const saveMaxAction = ({ value, type }) => {
    return {
        type: types.SAVE_MAX,
        payload: {
            value, type
        }
    }
};



export default {
    getMaxAction,
    saveMaxAction,
    updateMaxAction,
    resetMaxAction
}
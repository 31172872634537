import types from "./types";

export const getConferencesAction = () => {
  return {
    type: types.GET_CONFERENCES
  }
};

export const reserveAction = ({ project, pileId, accessCount, description }) => {
  return {
    type: types.RESERVE,
    project,
    pileId,
    accessCount,
    description
  }
};
export const terminateAction = ({ tag }) => {
  
  return {
    type: types.TERMINATE,
    tag
  }
};

export default {
  getConferencesAction,
  reserveAction,
  terminateAction
}
import PropTypes from "prop-types";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AlertContainer from "./alert";
import { removeAlertAction } from "../../../state/ducks/alerts/actions";

const Alerts = ({ actions, alerts }) => {
  const { removeAlertAction } = actions;
  
  return (
    <ul className="alerts">
      {alerts.map(alert => {
        const { id } = alert;       
        return (
          <AlertContainer key={id} {...alert} onDismissClick={() => removeAlertAction(id)}/>
        );
      })}
    </ul>
  );
};

Alerts.propTypes = {
    actions: 
        PropTypes.shape({
            removeAlertAction: PropTypes.func.isRequired
        }).isRequired,
    alerts: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ removeAlertAction }, dispatch)
});

const mapStateToProps = state => ({
    alerts: state.alertState
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);

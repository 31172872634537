import React from 'react';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { config } from '../../../../config';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      position: 'absolute',
      top: '0',
      height: '50px',
      left: '0',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    text: {
        color: 'white'
    }
  }));

function BannerAlert({ message, color }) {
    const classes = useStyles();
    let hex = color.length ? config.colors.find(c => c.color === color).hex : '';

    return (
        <div className={classes.root}>
            {message.length > 0 ?
                <Alert icon={false} style={{backgroundColor: hex, justifyContent: "center", width: '100%'}}>
                    <span className={classes.text}>{message}</span>
                </Alert>
                :
                <span></span>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    message: state.bannerState.message,
    color: state.bannerState.color
})

export default connect(mapStateToProps)(BannerAlert);
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SettingsIcon from '@material-ui/icons/Settings';
import LockIcon from '@material-ui/icons/Lock';
import ChatIcon from '@material-ui/icons/Chat';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { SecondaryButton } from '../../common/buttons/buttons';

//Styling to menu

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        marginTop: '5px'
    },
})((props) => (
    <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
            fontSize: '0.9rem'
        },
        "&:hover, &:active, &:visited": {
            backgroundColor: 'transparent',
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: '#1976d2'
            }
        }
    }
}))(MenuItem);

const StyledListItemIcon = withStyles((theme) => ({
    root: {
        minWidth: '30px'
    },
}))(ListItemIcon);


//Menu Component

function SettingsMenu({ isAdmin }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let adminMenu;

    if (isAdmin) {
        adminMenu = <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        >
                        <StyledMenuItem>
                            <StyledListItemIcon>
                                <LockIcon fontSize="small" />
                            </StyledListItemIcon>
                            <Link to="/manage"><ListItemText primary="Manage Account" /></Link>
                        </StyledMenuItem>
                        <StyledMenuItem>
                            <StyledListItemIcon>
                                <ChatIcon fontSize="small" />
                            </StyledListItemIcon>
                            <Link to="/banner"><ListItemText primary="Post Custom Banner" /></Link>
                        </StyledMenuItem>
                        <StyledMenuItem>
                            <StyledListItemIcon>
                                <FormatListNumberedIcon fontSize="small" />
                            </StyledListItemIcon>
                            <Link to="/accesspointmax"><ListItemText primary="Update Max Access Point" /></Link>
                        </StyledMenuItem>
                    </StyledMenu>;
    }
    else {
        adminMenu = <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        >
                        <StyledMenuItem>
                            <StyledListItemIcon>
                                <LockIcon fontSize="small" />
                            </StyledListItemIcon>
                            <Link to="/manage"><ListItemText primary="Change Password" /></Link>
                        </StyledMenuItem>
                    </StyledMenu>;
    }

    return (
        <div>
            <SecondaryButton 
                size="small" 
                variant="contained" 
                aria-controls="settings-menu" 
                aria-haspopup="true" 
                startIcon={<SettingsIcon />}
                onClick={handleClick}>
                Settings
            </SecondaryButton>
            {adminMenu}
        </div>
    );
}

//Gather relevant state from store
const mapStateToProps = state => ({
    isAdmin: state.authState.isAdmin
})

export default connect(mapStateToProps, null)(SettingsMenu);
import { call, put, takeLatest, takeEvery, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as services from './services';
import * as types from './types';
import { ADD_ALERT } from '../alerts/types';

function* getAccessPointMaxSaga() {
    try {
        const response = yield call(services.getAccessPointMaxService);
        yield put({ type: types.GET_MAX_SUCCESS, response });
            
    } catch(error) {
        if(parseInt(error.message) > 400) {
            yield put({ type: 'RESET_APP'});
            localStorage.removeItem('meetings');
            // yield put(push('/login', {isSessionExpired: true}))
        }
    }
  }

function* watchGetAccessPointMaxSaga() {
    yield takeEvery(types.GET_MAX, getAccessPointMaxSaga)
}

function* saveAccessPointMaxSaga(payload) {
    try {
        yield call(services.saveAccessPointMaxService, payload);
        yield put({type: types.SAVE_MAX_SUCCESS})
        
        const alertContent = {
            text: 'Max Access Points saved successfully.',
          }
        yield put({ type: ADD_ALERT, payload: alertContent } );

    } catch(error) {
        console.log(error.message);
        yield put({ type: types.SAVE_MAX_ERROR });

        const alertContent = {
            text: 'Error saving access point max.',
            severity: "error"
          }
        yield put({ type: ADD_ALERT, payload: alertContent } );
    }
  }

function* watchSaveAccessPointMaxSaga() {
    yield takeEvery(types.SAVE_MAX, saveAccessPointMaxSaga)
}


const accessPointMaxSagas = [
    fork(watchGetAccessPointMaxSaga),
    fork(watchSaveAccessPointMaxSaga)
  ];
  
  export default accessPointMaxSagas;